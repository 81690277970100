export interface ISubCategoriesModule 
{
    data: object;
}

export enum SubCategoriesModuleActions 
{
    fetchSubCategories = 'fetchSubCategories',
    fetchSubCategory = 'fetchSubCategory',
    fetchSubCategoriesById = 'fetchSubCategoriesById',
    fetchSubCreateCategory = 'fetchSubCreateCategory',
}
