import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { TextSelection } from 'prosemirror-state'
import Component from '../components/editor/Component.vue'

export default Node.create({
  name: 'vueComponent',

  group: 'block',

  atom: true,
  draggable: true,
  addAttributes() {
    return {
      count: {
        default: 0,
      },
      mode: {
        default: 'create',
      },
      content: [],
    }
  },
  addStorage() {
    return {
      mode: 'create',
    }
  },
  parseHTML() {
    return [
      {
        tag: 'vue-component',
      },
    ]
  },
  addCommands() {
    return {
      setSlider: (options) => ({ chain, commands }) => {
        return chain()
          .insertContent({ type: this.name,
            attrs: options })
          // set cursor after horizontal rule
          .command(({ tr, dispatch }) => {
            if (dispatch) {
              const { $to } = tr.selection
              const posAfter = $to.end()

              if ($to.nodeAfter) {
                tr.setSelection(TextSelection.create(tr.doc, $to.pos))
              } else {
                // add node after horizontal rule if it’s the end of the document
                const node = $to.parent.type.contentMatch.defaultType?.create()

                if (node) {
                  tr.insert(posAfter, node)
                  tr.setSelection(TextSelection.create(tr.doc, posAfter))
                }
              }

              tr.scrollIntoView()
            }

            return true
          })
          .run()
      },
    }
  },
  renderHTML({ HTMLAttributes }) {
    return ['vue-component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
})