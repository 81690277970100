import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'
import TeamsModule from '@/store/modules/teams/TeamsModule';
import MentionList from './MentionList.vue'

export default {
  items: ({ query }) => {
    return TeamsModule.teams.map((el) => {
        return {
          name: `${el.first_name} ${el.last_name}`,
          avatar: el.avatar,
          id: el.id,
        }
      }).filter(item => item.name.toLowerCase().startsWith(query.toLowerCase()))
  },

  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
          parent: this,
          propsData: props,
        })
        if (!props.clientRect) {
          return
        }
        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}