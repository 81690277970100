// TODO: remove this bus

import Vue from 'vue';

export const ContextMenuBus = new Vue({
  name: 'ContextMenuBus',
});

export enum BusEvents {
  spawnNode = 'spawnNode',
  destroyNode = 'destroyNode',
  destroyAll = 'destroyAll',
  updateNode = 'updateNode',
}
