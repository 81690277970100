import { mergeAttributes, Node } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';

const CustomParagraph = Node.create({
  name: 'customParagraph',
  group: 'block',
  content: 'text*',
  parseHTML() {
    return [{ tag: 'p.custom-paragraph' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(HTMLAttributes, { class: '' }), 0];
  },
  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },
});

export default CustomParagraph;