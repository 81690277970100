// import { IKnowledgeModule } from '@/store/modules/knowledge/IKnowledgeModule';
// import { getInitialLocale, Locales, Locale, loadLocale } from '@/services/i18n';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
// import { HEADERS } from '@/services/helpers/constants';
// import SignupRequest from '@/models/dto/signup.request';
import ApiClient from '@/api/api.client';
// import AuthRequest from '@/models/dto/auth.request';
// import { InviteData } from '@/models/invite';
// import UsersModule from '../users/UsersModule';
import { ISubCategoriesModule } from '@/store/modules/subcategories/ISubCategoriesModule';
import { SubCategoriesModuleActions as Actions } from '@/store/modules/subcategories/ISubCategoriesModule';
import SubCategoriesResponse from '@/models/dto/responses/subcategories.response';
import { SubCategories } from '@/models/dto/responses/subcategories.response';
import { AxiosError } from 'axios';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';
interface SubCategoriesDataResponse
{
    data: SubCategories
}
@Module({
  dynamic: true,
  name: StoreModules.SubCategories,
  namespaced: true,
  store,
})
class SubCategoriesModule extends VuexModule implements ISubCategoriesModule {
  data = {};
  subcategories = {}

  @Mutation
  public getData(data: SubCategoriesDataResponse) {
    this.data = data.data;
  }
  @Mutation
  public setSubcategories(data: SubCategoriesDataResponse) {
    this.subcategories = data.data;
  }

  @Action
  async [Actions.fetchSubCreateCategory](subCat: {parentId: number, name: string})
  {
    try
    {
        const result = await ApiClient.subCategories.createSubCategories(subCat.name, subCat.parentId)
        this.getData(result);
        return result
    }
    catch(error)
    {
      const typedError = error as AxiosError;
      const errorCode = ((typedError || {}).response || {}).data.error || 'Errors.Global_Error';
      const message = typedError.response.data.name[0];
      if(message.includes('exist')) {
        Notify.error(i18n.t('Errors.NoDublicates').toString());
      }
      else if(message.includes('required')) {
        Notify.error(i18n.t('Errors.NoEmptyFields').toString());
      } else {
        Notify.error(i18n.t(errorCode).toString());
      }
      return Promise.reject(typedError);
    }
  }

  @Action
  async [Actions.fetchSubCategories](parentId: number) 
  {
    try
    {
        const result = await ApiClient.subCategories.getSubCategories(parentId)
        this.getData(result);
    }
    catch(e)
    {
        console.log('er')
    }
  }
  @Action
  async [Actions.fetchSubCategoriesById](id: number) 
  {
    try
    {
        const result = await ApiClient.subCategories.getSubCategoriesById(id)
        this.setSubcategories(result);
    }
    catch(e)
    {
        console.log('er')
    }
  }
}

export default getModule(SubCategoriesModule);