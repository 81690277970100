










































import { Vue, Component, Prop } from 'vue-property-decorator';

import Icon from '@/components/ui/icon/Icon.vue';
import OptionEditor from '@/components/context-menu/repeatable/OptionEditor.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';

import { ContextMenuItemType, IContextMenuItem } from '@/components/context-menu';
import { BusEvents, ContextMenuBus } from '@/components/context-menu/ContextMenuBus';

import ApiClient from '@/api/api.client';

import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';

import Notify from '@/services/helpers/notify';
import UsersModule from '@/store/modules/users/UsersModule';

@Component({
  components: {
    Icon,
    OptionEditor,
    DeleteContext,
  },
})
export default class CardsStackLink extends Vue {
  public external: boolean;
  public isHovered = false;
  public isOptionsExpanded = false;
  public showDeleteContext = false;

  @Prop() id: number;
  @Prop({ required: true }) to: string;
  @Prop({ default: false }) isNew: boolean;
  @Prop({ default: 'div' }) wrapperTag: string;
  @Prop({ default: '' }) title: string;

  get options(): Array<IContextMenuItem> {
    const $t = this.$t.bind(this);

    return [
      {
        type: ContextMenuItemType.link,
        text: $t('General.Edit') as string,
        action: node => {
          ContextMenuBus.$emit(BusEvents.destroyNode, node);
        },
      },
      {
        type: ContextMenuItemType.link,
        text: $t('General.Delete') as string,
        state: 'danger',
        action: node => {
          ContextMenuBus.$emit(BusEvents.destroyNode, node);
        },
      },
    ];
  }
  get userCanEdit()
  {
    return UsersModule.currentUser.admin || 
    UsersModule.currentUser.permitions.knowledge.manage
  }
  hideOption() {
    if(!this.isOptionsExpanded)
    {
      this.isHovered = false;
    }
  }

  showOption()
  {
    this.isOptionsExpanded = true;
  }

  closeOptions() {
    this.isHovered = false;
    this.isOptionsExpanded = false;
  }

  openDeleteContext()
  {
    this.showDeleteContext = true;
  }

  closeDeleteContext()
  {
    this.showDeleteContext = false;
  }

  deleteItem()
  {
    ApiClient.knowledge.deleteKnowledge(this.id).then(() =>{
      Notify.success('Знание удалено');
      KnowledgeModule.deleteLocalSubcategoryKnowledges(this.id)
    });
  }

  created() {
    this.external = /^\w+:\/\//.test(this.to);
  }
}
