





























































































































import { Component, Vue } from 'vue-property-decorator';
import CardsStack from '@/components/documents/cards-stack';

import { Columns } from 'vue-columns';
import DropdownTitle from '@/components/ui/dropdown-title';
import { ContextMenuItemType, IContextMenuSelector } from '@/components/context-menu';
import { BusEvents, ContextMenuBus } from '@/components/context-menu/ContextMenuBus';
import SelectorBar from '@/modules/settings/components/selector-bar/SelectorBar.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import EmptyBackplate from '@/components/app-bar/EmptyBackplate.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import FiltersMobile from '@/components/team/FiltersMobile.vue';
import Tabs from '@/components/ui/tabs';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import { Specialisations, SubRole, Tag as KnowledgeTag } from '@/typings/domain';
import RolesModule from '@/store/modules/roles/RolesModule';
import TagsModule from '@/store/modules/tags/TagsModule';
import Submenu from '@/components/ui/submenu/Submenu.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import Tags from '@/components/ui/tag/Tag.vue';
import { Tag } from '@/typings/domain';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';
import ApiClient from '@/api/api.client';

@Component({
  components: {
    CardsStack,
    Columns,
    DropdownTitle,
    SelectorBar,
    EmptyBackplate,
    Icon,
    Dropdown,
    FiltersMobile,
    Tags,
    Tabs,
    ContextMenuLink,
    ContextMenuSpawner,
    Submenu,
  },
})
export default class DocumentsDashboard extends Vue {
  public selectedPosition: string | number = '';
  public $isMobile: boolean;
  public $mq: number;
  public submenuStyles: object = { opacity: 0, transition: 'unset' };
  public isSearchInFocus = false;
  public searchQuery: Array<SubRole> = [];
  public specsOfRole = UsersModule.currentUser.specialisations;
  public selectedTab = '';
  public hideSelectorBar = false;
  public selectedTagTab = '';
  public knowledgesWithoutFilter: any = null;
  public filterState = {
    global: this.$t('Documents.Knowledges.MyKnowledges'),
    role: '',
    state: this.$t('Documents.Knowledges.MyKnowledges'),
    query: {
      role: [] as Array<SubRole>,
      tag: {} as KnowledgeTag,
    },
  }

  /**
   * Displays in fliters
   */
  public selectedSpecializations: any = [];
  public page = 1;
  public pageLoaded = false;
  public roles: Array<{title: string, value: number}> = [];
  public selectedTag: KnowledgeTag | {} = {};
  public filteredItems: Array<any> = []
  public selectedSpecialization = null as Tag;
  public filterSpecialisations: any = [];
  public deselectSpecialization(specialisation: string | number) {
    const index = this.selectedSpecializations.indexOf(specialisation as never);
    if (index !== -1) this.selectedSpecializations.splice(index, 1);
    this.resetSpecialistFilter();
  }

  public get specialisations() {
    return RolesModule.specialisations
  }
  public get isKnowledgeLoading()
  {
    return KnowledgeModule.isKnowledgeLoading
  }
  public get mySpecialisations() {
    return UsersModule.currentUser.specialisations
  }
  public get tags() {
    return TagsModule.tags
  }
  public get filterStatus()
  {
    return KnowledgeModule.filter;
  }
  public get actualRoles() {
    return RolesModule.actualRoles
  }
  public get specialisationsData(){
    return SpecializationsModule.specialisations.map((el) => 
    {
      return {
        title: el.name,
        value: el.id,
      }
    });
  }

  public get positions(): Array<IContextMenuSelector> {
    return [
      {
        type: ContextMenuItemType.gridSelector,
        options: [
          { text: this.$t('General.Positions.Designer') as string, value: '1' },
          { text: this.$t('General.Positions.Developer') as string, value: '2' },
          { text: this.$t('General.Positions.Strategist') as string, value: '3' },
          { text: this.$t('General.Positions.Targetologist') as string, value: '33' },
          { text: this.$t('General.Positions.WebDeveloper') as string, value: '4' },
          { text: this.$t('General.Roles.Specialist') as string, value: '5' },
        ],
        onChange: value => {
          this.selectedPosition = value[0];
          ContextMenuBus.$emit(BusEvents.destroyAll);
        },
      },
    ];
  }
  
  public get selectedPositionTitle() {
    return this.positions[0].options.filter(option => {
      return option.value === this.selectedPosition;
    })[0]?.text;
  }
  filterBySpecialisation(value: Tag)
  { 
    this.selectedSpecialization = value;
    TagsModule.fetchKnowledgeTags({specialisation: value.id})
    this.filterKnowledgeByAllSpecialisations(value.id);

    this.hideSelectorBar = true
  }
  specSelected(spec: any)
  { 
    SettingsModule.fetchSettings().then(() => 
    { 
      if(!this.sectionKnowledge)
      {
        this.$router.push('/team')
      }
    });
    this.page = 1;
    this.resetTagFilter();
    TagsModule.fetchKnowledgeTags({specialisation: spec})
    this.selectedSpecializations.push({id: spec})
    this.filterKnowledgeByMySpecialisations(spec)
  }
  
  async filterAllKnowledges()
  {
    this.resetTagFilter()
    this.page = 1;
    this.filterState.state = 'Все знания';
    this.hideSelectorBar = true;
    this.selectedSpecializations = [];
    await KnowledgeModule.fetchKnowledge({ page: this.page })
    TagsModule.fetchTags();
    this.page++;
  }
  async filterMyKnowledges()
  {
    this.resetTagFilter()
    this.page = 1;
    this.filterState.state = 'Мои знания';
    this.hideSelectorBar = false;
    this.filterKnowledgeByMySpecialisations(this.specialisationsData[0].value)
    TagsModule.fetchKnowledgeTags({specialisation: this.specialisationsData[0].value})
    this.selectedSpecializations.push({id: this.specialisationsData[0].value})
  }
  get knowledgeData() {
    if (KnowledgeModule.data && KnowledgeModule.data.length)
      return KnowledgeModule.data;
    else if (this.knowledgesWithoutFilter)
      return this.knowledgesWithoutFilter;
	else
		return false;
  }

  mounted() {
    if(!RolesModule.roles.length)
      RolesModule.fetchRoles();

    KnowledgeModule.clearKnoledges();

    SpecializationsModule.fetchSpecialisations({condition: 'my'}).then(() => 
    {
      this.filterSpecialisations = this.specialisationsData;
      if(this.filterSpecialisations.length)
      {
        this.specSelected(this.specialisationsData.length && this.specialisationsData[0].value)
      }
      else
      {
        ApiClient.knowledge.getKnowles()
         .then((res) => this.knowledgesWithoutFilter = res.data);
      }
    }).finally(() =>
    {
      this.pageLoaded = true;
    })

    // const intersectionObserver = new IntersectionObserver(entries => {
    //   if (entries[0].intersectionRatio <= 0) return;
    //     if(this.knowledgeData.length)
    //     {
    //       this.loadItems();
    //     }
    // });
    // intersectionObserver.observe(document.querySelector('.knowledge-grid__end'));
  }
  /*
  **  Выбор специализации и вызов
  **  фильтра для данной специализации
  */

  onFocus() {
    this.isSearchInFocus = true;
  }
  get sectionKnowledge()
  {
    return this.settings[this.settings.findIndex((el) => el.slug === 'knowledge')]
  }
  public get settings()
  {
    return SettingsModule.getSettings
  }
  async loadItems()
  {
    if(this.selectedSpecializations.length || this.selectedTag)
    { 
      await KnowledgeModule.fetchKnowledge({ specialisations:  {...this.selectedSpecializations }, tags: this.selectedTag, page: this.page })
    }
    else
    {
      await KnowledgeModule.fetchKnowledge({ page: this.page })
    }
    this.page++;
  }
  onInput(event: { target: HTMLInputElement }) {
    const query = event.target.value.trim().toLowerCase();
    const res = this.specialisations.filter(obj => obj.name.toLowerCase().includes(query));
    this.searchQuery = res;
  }

  pickSearchQueryItem(item: SubRole) {
    const parentRole = this.actualRoles.find(el => el.id === item.role_id);
    const filterMenu = this.$refs.filterMenu as FiltersMobile;

    if (this.filterState.global === this.$t('Documents.Knowledges.MyKnowledges')) {
      this.filterState.global = this.$t('Documents.Knowledges.AllKnowledges')
    }

    this.filterState.role = `${parentRole.id}`;
    this.selectedTab = `[${item.id}]`;
    this.specsOfRole = parentRole.specialisations as any;
    this.selectedSpecializations.pop();
    this.selectedSpecializations = this.selectedSpecializations.concat(parentRole);
    this.searchQuery = [];
    this.isSearchInFocus = false;

    this.pickTabItem([item]);
    filterMenu.close();
  }

  pickTagTab(tab: [KnowledgeTag]) {
    this.filterState.query.tag = tab[0];
    this.applyMobileFilters();
  }

  pickTabItem(tab: [SubRole]) {
    if (!this.selectedTab.length) {
      this.selectedTab = `[${tab[0].id}]`;
    }
    this.filterState.query.role = tab;
    this.applyMobileFilters();
  }

  roleStateChange(value: string) {
    this.filterState.role = value;
  }

  addToSelectedSpecialisation(value: SubRole, type = '') {
    
    this.selectedSpecializations.pop();
    this.selectedSpecializations = this.selectedSpecializations.concat(value);
    if (this.filterState.global === this.$t('Documents.Knowledges.MyKnowledges')) {
      this.filterState.global = this.$t('Documents.Knowledges.AllKnowledges')
    }

    if (Object.keys(this.selectedSpecializations[0]).includes('specialisations')) {
      this.specsOfRole = this.selectedSpecializations[0].specialisations;
      this.roleStateChange(`${value.id}`);
    } else {
      this.applyFilters();
    }
  }

  addToSelectedTags(tag: KnowledgeTag) {
    this.selectedTag = tag;
    this.applyFilters();
    console.log('obj');
  }

  applyMobileFilters() {
    KnowledgeModule.clearKnoledges();
    this.page = 1;
    (KnowledgeModule.fetchKnowledge({ specialisations: { ...this.filterState.query.role }, tags: this.filterState.query.tag, page: 1})) as unknown as SubRole
  }

  applyFilters() {
    this.page = 1
    KnowledgeModule.setFilterStatus();
    KnowledgeModule.clearKnoledges();
    this.loadItems();
  }

  resetTagFilter(value?: string) {
    this.page = 1;
    KnowledgeModule.offFilterStatus();
    this.selectedTag = {};
    KnowledgeModule.clearKnoledges();
    if(value === 'tag')
    {
      this.loadItems();
    }
  }

  resetSpecialistFilter() {
    this.hideSelectorBar = false;
    this.selectedSpecialization = null;
    
    this.resetTagFilter();
    TagsModule.fetchKnowledgeTags({specialisation: this.specialisationsData[0].value})
    this.selectedSpecializations = []
    this.selectedSpecializations.push(({id: this.specialisationsData[0].value}))
    
    if (this.$isMobile) {
      this.specsOfRole = UsersModule.currentUser.specialisations;
      this.filterState.role = '';
      if (this.filterState.global === this.$t('Documents.Knowledges.AllKnowledges')) {
        this.filterState.global = this.$t('Documents.Knowledges.MyKnowledges')
      }
    }
    this.applyFilters();
  }

  onDropdownToggle(value: boolean) {
    setTimeout(() => {
      this.submenuStyles = value
        ? {}
        : { opacity: 0, transition: 'unset' };
    }, 300);
  }

  filterKnowledgeByAllSpecialisations(elemntId: number)
  { 
    this.selectedSpecializations = [{id: elemntId}];
    KnowledgeModule.fetchKnowledge({ specialisations: this.selectedSpecializations, tags: this.selectedTag, page: 1})
    this.page++;
  }
  filterKnowledgeByMySpecialisations(elemntId: number)
  { 
    let selectedSpec  = this.specialisationsData.filter((el) => el.value === elemntId) as any
    selectedSpec = selectedSpec.map((el: {title: string, value: number}) => 
    {
      return {
        id: el.value,
      }
    })
    this.selectedSpecializations = selectedSpec;
    KnowledgeModule.fetchKnowledge({ specialisations: this.selectedSpecializations, tags: this.selectedTag, page: 1})
    this.page++;
  }
}
