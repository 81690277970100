
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PreloaderWrap extends Vue {
  @Prop() loading: boolean;
}
