












































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import CardsStackLinksGroup from './CardsStackLinksGroup.vue';
import Editor from '@/modules/settings/views/directions/components/Editor.vue';
import { ContextMenuItemType, IContextMenuItem } from '@/components/context-menu';
import OptionEditor from '@/components/context-menu/repeatable/OptionEditor.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import { BusEvents, ContextMenuBus } from '@/components/context-menu/ContextMenuBus';

import ApiClient from '@/api/api.client';

import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';

import Notify from '@/services/helpers/notify';
import { Specialisations } from '@/typings/domain';
import UsersModule from '@/store/modules/users/UsersModule';

@Component({
    components: {
        Icon,
        Editor,
        ContextMenuSpawner,
        CardsStackLinksGroup,
        OptionEditor,
        DeleteContext,
    },
})
export default class CardsStack extends Vue {
    public titleShowButtons = false;
    public isOptionsVisible = false;
    public isTitleEditable = false;
    public isPinned = false;
    public optionEditCategoryOpen = false;
    public isExpanded = false;
    public optionEditorIsOpen = false;
    public isTitleHovered = false;
    public showDeleteContext = false;
    public innerTitle: string;

    @Prop({ default: null }) id: number;
    @Prop() subcategories: object;
    @Prop() items: object;
    @Prop({ required: true }) title: string;
    @Prop({ default: false }) pinned: boolean;

    created() {
        this.innerTitle = this.title;
    }

    @Watch('$isMobile')
    onResize(value: boolean) {
        if (value && !this.isTitleEditable) this.isExpanded = false;
    }

    updateCategory(value: { name: string }) {
        if (value.name) {
            ApiClient.categories.updateCategory(this.id, { name: value.name }).then(() => {
                KnowledgeModule.updateCategoryName({ id: this.id, name: value.name });
                Notify.success('Категория изменена');
                this.closeEditor();
            });
        }
    }

    edit() {
        this.optionEditCategoryOpen = true;
        this.isTitleHovered = false;
    }
    closeEditor() {
        this.optionEditCategoryOpen = false;
        this.optionEditorIsOpen = false;
        this.isTitleHovered = false;
    }
    hideOption() {
        if (!this.optionEditorIsOpen) {
            this.isTitleHovered = false;
        }
    }

    onExpandToggle() {
        if (this.isTitleEditable) return;
        this.isExpanded = !this.isExpanded;
    }
    get userCanEdit() {
        return UsersModule.currentUser.admin || UsersModule.currentUser.permitions.knowledge.manage;
    }
    public get moreMenu(): Array<IContextMenuItem> {
        const isPinned = this.isPinned;
        const $t = this.$t.bind(this);

        return [
            {
                type: ContextMenuItemType.link,
                text: $t('General.Rename') as string,
                action: node => {
                    const ref: HTMLElement = this.$refs.title as HTMLElement;
                    this.isTitleEditable = true;
                    ContextMenuBus.$emit(BusEvents.destroyNode, node);

                    setTimeout(() => {
                        const selection = window.getSelection();
                        const range = document.createRange();
                        selection.removeAllRanges();
                        range.selectNodeContents(ref);
                        range.collapse(false);
                        selection.addRange(range);
                        ref.focus();
                    }, 0);
                },
            },
            {
                type: ContextMenuItemType.link,
                get text() {
                    return isPinned ? ($t('General.Unpin') as string) : ($t('General.Pin') as string);
                },
                action: node => {
                    this.isPinned = !this.isPinned;
                    ContextMenuBus.$emit(BusEvents.destroyNode, node);
                },
            },
        ];
    }

    @Emit('titleChange')
    saveTitle(e: Event & { target: HTMLElement }) {
        this.isTitleEditable = false;

        setTimeout(() => {
            this.innerTitle = e.target.innerText;
        }, 0);

        return this.innerTitle;
    }

    async deleteItem() {
        ApiClient.categories.deleteCategory(this.id).then(() => {
            Notify.success('Категория удалена');
            KnowledgeModule.deleteLocalCategory(this.id);
        });
    }

    pin() {
        const id = this.id;
        const state = true;

        ApiClient.categories.updateCategory(id, { pinned: state }).then(() => {
            KnowledgeModule.updateCategoryPinned({ id, state: true });
            KnowledgeModule.updateSort();
        });
    }

    unpin() {
        const id = this.id;
        const state = false;

        ApiClient.categories.updateCategory(id, { pinned: state }).then(() => {
            KnowledgeModule.updateCategoryPinned({ id, state: false });
            KnowledgeModule.updateSort();
        });
    }

    openDeleteContext() {
        this.showDeleteContext = true;
    }

    closeDeleteContext() {
        this.showDeleteContext = false;
    }

	disableHover() {
		this.isTitleHovered = false;
	}
}
