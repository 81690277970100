




































































































import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import { ContextMenuItemType, IContextMenuItem } from '@/components/context-menu';
import { BusEvents, ContextMenuBus } from '@/components/context-menu/ContextMenuBus';

import CardsStackLink from './CardsStackLink.vue';
import OptionEditor from '@/components/context-menu/repeatable/OptionEditor.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue'

import ApiClient from '@/api/api.client';

import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';

import { IKnowledge } from '@/models/knowledges';
import { ISubCategory } from '@/models/subCategories';

import Notify from '@/services/helpers/notify';
import UsersModule from '@/store/modules/users/UsersModule';
import specialisations from '@/api/specialisations/specialisations.api';
import { Specialisations } from '@/typings/domain';

@Component({
  components: {
    CardsStackLink,
    OptionEditor,
    Icon,
    DeleteContext,
  },
})
export default class CardsStackLinksGroup extends Vue {
  isExpanded = false;
  isOpened = false;
  external: boolean;
  isOptionsExpanded = false;
  isTitleHovered = false;
  showDeleteContext = false;
  allSpoiler = 0;

  @Prop() to: string;
  @Prop() lengthOfItems: number;
  @Prop() subTitleItem: IKnowledge;
  @Prop() subCategoriesItem: ISubCategory;
  @Prop({ default: false}) isNew: boolean;
  hideOption() {
    if(!this.isOpened)
    {
      this.isTitleHovered = false;
    }
  }


  // Если знание без подкатегории
  // не открываем
  expandIfNotKnowledge()
  {
    if(this.subCategoriesItem)
    {
      this.isExpanded = !this.isExpanded
    }
    else
    {
      this.$router.push(`/documents/${this.subTitleItem.id}`).catch(() => {return})
    }
  }



  get userCanEdit()
  {
    return UsersModule.currentUser.admin || 
    UsersModule.currentUser.permitions.knowledge.manage
  }
  closeOptions() {
    this.isTitleHovered = false;
    this.isOpened = false;
  }



  openDeleteContext()
  {
    this.showDeleteContext = true
  }

  closeDeleteContext()
  {
    this.showDeleteContext = false
  }

  deleteItem() {
    const id = this.subTitleItem ? this.subTitleItem.id : this.subCategoriesItem.id;

    if (this.subTitleItem)
    {
      ApiClient.knowledge.deleteKnowledge(id).then(() => {
        Notify.success('Знание удалено');
        KnowledgeModule.deleteLocalKnowledges(id)
      });
    }
    else
    {
      ApiClient.subCategories.deleteSubcategory(id).then(() => {
        Notify.success('Подкатегория удалена');
        KnowledgeModule.deleteLocalSubcategory(id)
      });
    }
  }

  get title()
  {
    return this.subTitleItem ? this.subTitleItem.title : this.subCategoriesItem.name;
  }

  get deleteText()
  {
    return this.subTitleItem ? `Удалить знание “${this.subTitleItem.title}“` : `Удалить подкатегорию “${this.subCategoriesItem.name}“`;
  }

  getSubCategoriesItemsLength()
  {
    const lengthSub = this.subCategoriesItem?.items?.length;
    return lengthSub;
  }

  someOtherMethod()
  {
    const lengthSub = this.getSubCategoriesItemsLength();
    this.allSpoiler = lengthSub + this.lengthOfItems
    if (this.allSpoiler >= 15)
    {
      this.isExpanded = true;
    }
    else
    {
      this.isExpanded = false;
    }
  }
  created() {
    this.external = /^\w+:\/\//.test(this.to);
    this.someOtherMethod()
  }
  get options(): Array<IContextMenuItem> {
    const $t = this.$t.bind(this);
    return [
      {
        type: ContextMenuItemType.link,
        text: $t('General.Edit') as string,
        action: node => {
          ContextMenuBus.$emit(BusEvents.destroyNode, node);
        },
      },
      {
        type: ContextMenuItemType.link,
        text: $t('General.Delete') as string,
        state: 'danger',
        action: node => {
          ContextMenuBus.$emit(BusEvents.destroyNode, node);
        },
      },
    ];
  }
}
