<template>
    <div class="link-modal-wr">
        <div class="link-modal" v-click-outside="closeLinkModal">
            <h2 class="link-modal__label">Вставьте ссылку</h2>
            <input type="text" class="link-modal__value" placeholder="yandex.ru" v-model="link" ref="input">
            <button class="link-modal__confirm" @click="saveLink">
                Сохранить
            </button>    
        </div>
    </div>
</template>
<script>
export default{
    props: ['previousUrl'],
    data(){
        return{
            link: '',
        }
    },
    mounted()
    {
        this.$refs.input.focus()
    },
    watch: 
    {
        previousUrl(val)
        {
            this.link =  val
        },
    },
    methods:{
        closeLinkModal()
        {
            this.$emit('close',true)
        }, 
        saveLink()
        {
            this.$emit('select',this.link)
        },
    },
}
</script>
<style lang="scss">
.link-modal-wr::before
{
    content: '';
    display: block;
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100vw;
    top: 0;
    background: rgba($color: #000000, $alpha: 0.4);
    z-index: 9999;
}
.link-modal
{
    position: fixed;
    z-index: 10000;
    text-align: center;
    background: #191919;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.7);
    border-radius: 19px;
    width: 356px;
    left: 0;
    padding: 23px 35px;
    font-family: 'Museo Sans Cyrl';
    top: 50%;
    right: 0;
    transform: translate(0,-60%);
    margin-left: auto;
    margin-right: auto;
    .link-modal__confirm
    {
        cursor: pointer;
        background-color: #f7d547;
        font-family: 'Museo Sans Cyrl';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 144%;
        border-radius: 19px;
        padding: 8px 18px;
        text-align: center;
        color: #000000;
        outline: none;
        border: none;
    }
    .link-modal__label
    {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 144%;
        color: rgba(255, 255, 255, 0.8);
    }
    .link-modal__value
    {
        background: none;
        text-align: center;
        padding: 13px 0;
        width: 100%;
        outline: none;
        color: #fff;
        margin: 0 auto 32px auto;
        display: block;
        border: 0.7px solid rgba(255, 255, 255, 0.15);
        border-radius: 19px;
    }
}
@media (max-width: 960px) {
    .link-modal
    {
        width: calc(100% - 48px);
        padding: 23px 24px;
    }
}
</style>