// import { IKnowledgeModule } from '@/store/modules/knowledge/IKnowledgeModule';
// import { getInitialLocale, Locales, Locale, loadLocale } from '@/services/i18n';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
// import { HEADERS } from '@/services/helpers/constants';
// import SignupRequest from '@/models/dto/signup.request';
import ApiClient from '@/api/api.client';
// import AuthRequest from '@/models/dto/auth.request';
// import { InviteData } from '@/models/invite';
// import UsersModule from '../users/UsersModule';
import { ICategoriesModule } from '@/store/modules/categories/ICategoriesModule';
import ResponsesCategoriesData, { CategoriesResponse } from '@/models/dto/responses/categories.response';
import { CategoriesModuleActions as Actions } from '@/store/modules/categories/ICategoriesModule';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';
import { AxiosError } from 'axios';
interface CategoriesData
{
    data: CategoriesResponse[]
}
interface CategoryData
{
    data: CategoriesResponse
}
@Module({
  dynamic: true,
  name: StoreModules.Categories,
  namespaced: true,
  store,
})
class CategoriesModule extends VuexModule implements ICategoriesModule {
  data = [] as any;
  category = {};
  createdCategory = {}

  @Mutation
  public getData(data: CategoriesData) {
    this.data = data.data;
  }
  @Mutation
  public setCategory(data:CategoryData)
  {
    this.category = data.data 
  }

  @Mutation
  public appendCategory(data:CategoryData)
  {
    this.data = this.data.concat(data.data);
  }
  @Mutation
  public setCreatedCategory(data:CategoryData)
  {
    this.createdCategory = data.data
    this.data.push(data.data)

  }

  @Action
  async [Actions.fetchCategories]() {
    const result = await ApiClient.categories.getCategories();
    this.getData(result);
  }
  @Action
  async [Actions.fetchCategory](id:string) {
    const result = await ApiClient.categories.getCategory(id);
    this.setCategory(result);
  }
  @Action
  async [Actions.fetchCreateCategory](name: string, pinned: boolean) {
    try {
      const result = await ApiClient.categories.createCategories(name, pinned);
      this.setCreatedCategory(result);
      return result
    } 
    catch(error) {
      const typedError = error as AxiosError;
      const errorCode = ((typedError || {}).response || {}).data.error || 'Errors.Global_Error';
      if(typedError.message && typedError.message.includes('422')) {
        if(typedError.response.data.name[0].includes('unique')) {
          Notify.error(i18n.t('Errors.NoDublicates').toString());
        }
        } else {
          Notify.error(i18n.t(errorCode).toString());
        }
      return Promise.reject(typedError);
    }
  }
}

export default getModule(CategoriesModule);