


































import Icon from '@/components/ui/icon/Icon.vue';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { RolesModuleActions } from '@/store/modules/roles/IRolesModule';
import UsersModule from '@/store/modules/users/UsersModule';

@Component({
    components: {
        Icon,
    },
})
export default class OptionEditor extends Vue {
    optionMoreClicked = false;
    isTitleHoveredProp = false;
    isShowDeleteContext = false;

    @Prop() id: number;
    @Prop() canEdit: boolean;
    @Prop() isTitleHovered: boolean;
    @Prop() isSubCategory: boolean;
    @Prop() isOpened: boolean;
    @Prop({ default: '' }) editLink: string;
    @Prop({ default: false }) canPin: boolean;
    @Prop({ default: true }) showEdit: boolean;
    @Prop({ default: false }) canUnpin: boolean;
    @Prop({ default: false }) canDelete: boolean;
    public deleteId: number = null;
    mounted() {
        this.deleteId = this.id;
    }
    @Watch('isTitleHovered')
    onTitleHoveredChange(val: boolean) {
        this.isTitleHoveredProp = val;
    }
    showDeleteContext() {
        this.isShowDeleteContext = true;
        this.optionMoreClicked = false;
    }
    get curretUser() {
        return UsersModule.currentUser;
    }
    deletedItem() {
        this.emitDelete(this.id);
    }
    closedByOutsideClick(event: { target: { closest: (arg0: string) => any; }; }) {
        this.isTitleHoveredProp = false;
        this.isShowDeleteContext = false;
        this.optionMoreClicked = false;
        this.close();
        if (!event.target.closest('.cards-stack__header')) {
            this.disbleHoverItem();
        }
    }
    @Emit('close')
    close() {
        return true;
    }
    @Emit('delete')
    emitDelete(itemId: number) {
        return itemId;
    }
    @Emit('pin')
    pinItem() {
        return true;
    }
    @Emit('unpin')
    unpinItem() {
        return false;
    }

    @Emit('edit')
    editItem() {
        this.editLink ? this.$router.push(this.editLink) : null;
        this.isTitleHoveredProp = false;
        this.isShowDeleteContext = false;
        this.optionMoreClicked = false;
        this.close();
        return true;
    }

    @Emit('disbleHover')
    disbleHoverItem() {
        return false;
    }
}
