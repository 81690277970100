









import UsersModule from '@/store/modules/users/UsersModule';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import KnowledgeWithDirection from './projectTypes/KnowledgeWithDirection.vue'
import DocumentKnowledgesType from './ProjectsKnowledgesType.vue'
import KnowledgeWithProjectName from './projectTypes/KnowledgeWithProjectName.vue'
import { NamedItem } from '@/models/inputs';
@Component({
  components: 
  {
    DocumentKnowledgesType, 
    KnowledgeWithDirection,
    KnowledgeWithProjectName,
  },
})
export default class DocumentMetaProjectsKnowledge extends Vue 
{
  public selected = Array<number>();
  get user()
  {
    return UsersModule.currentUser;
  }
  @Emit('changeProjectName')
  setProjectIds(projectIds: Array<number>)
  {
    this.selected = projectIds
    return this.selected
  }


  get companyType()
  {
    if(this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'many')
    {
      return 'KnowledgeWithDirection'
    }
    if(this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'one')
    {
      return 'KnowledgeWithProjectName'
    }
    return ''
  }
  
  @Emit('loaded')
  pageLoaded()
  {
    return true;
  }

  @Emit('change')
  directionUpdated(item:Array<number>)
  {
    this.selected = item; 
    return this.selected
  }
}
