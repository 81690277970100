











import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import UsersModule from '@/store/modules/users/UsersModule';
@Component({
    components: 
    {
        Icon,
    },
})
export default class EmptyBackplate extends Vue 
{
    @Prop({default: null}) message: string;
    @Prop({default: null}) to: string;

    get currentUser()
    {
        return UsersModule.currentUser
    }
}
